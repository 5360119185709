import { ApiGatewayService } from './index';
import { RawAxiosRequestHeaders } from 'axios';

interface CsrfResponse {
  csrfToken: string;
}

interface TestResponse {
  message: string;
}

export class CsrfService {
  private tokenPromise: Promise<string> | null;
  private apiGateway: ApiGatewayService;
  private lastTokenRefresh: number = 0;
  private TOKEN_REFRESH_INTERVAL = 4 * 60 * 1000; // 4 minutes

  constructor(apiGateway: ApiGatewayService) {
    this.tokenPromise = null;
    this.apiGateway = apiGateway;
  }

  async getCsrfToken(): Promise<string> {
    try {
      this.tokenPromise = null;
      this.tokenPromise = (async () => {
        const response = await this.apiGateway.request<CsrfResponse>({
          method: 'GET',
          url: '/api/auth/csrf-token'
        });
        
        if (!response.data) {
          throw new Error('No CSRF token received');
        }
        
        return response.data.csrfToken;
      })();
      
      return await this.tokenPromise;
    } catch (error) {
      console.warn('CSRF token fetch failed:', error);
      this.tokenPromise = null;
      return '';
    }
  }

  getStoredToken(): string {
    const token = document.cookie
      .split('; ')
      .find(row => row.startsWith('XSRF-TOKEN='))
      ?.split('=')[1];
    
    return token || '';
  }

  async ensureValidToken(): Promise<string> {
    const currentToken = this.getStoredToken();
    const now = Date.now();

    // Add debounce for rapid refreshes
    if (currentToken && (now - this.lastTokenRefresh < 1000)) {
      return currentToken;
    }

    if (!currentToken || (now - this.lastTokenRefresh > this.TOKEN_REFRESH_INTERVAL)) {
      try {
        const newToken = await this.getCsrfToken();
        this.lastTokenRefresh = now;
        return newToken;
      } catch (error) {
        if (currentToken) {
          return currentToken;
        }
        console.warn('Failed to refresh CSRF token:', error);
        return '';
      }
    }
    return currentToken;
  }

  async addTokenToHeaders(headers: RawAxiosRequestHeaders = {}): Promise<RawAxiosRequestHeaders> {
    try {
      const token = await this.ensureValidToken();
      return {
        ...headers,
        'X-XSRF-TOKEN': token
      };
    } catch (error) {
      console.warn('Failed to add CSRF token to headers:', error);
      return headers;
    }
  }

  async testCsrf(): Promise<boolean> {
    try {
      await this.getCsrfToken();
      
      const headers = await this.addTokenToHeaders({});
      const response = await this.apiGateway.request<TestResponse>({
        method: 'POST',
        url: '/api/auth/test-csrf',
        headers
      });
      
      if (!response.data) {
        return false;
      }
      
      return response.data.message === 'CSRF test successful';
    } catch (error) {
      console.error('CSRF Test Failed:', error);
      throw error;
    }
  }
} 