import React from 'react';
import { NavLink } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import BasteonLogo from '../../assets/basteon-logo.svg';
import { Subscription } from '../Subscription';
import {
  CubeTransparentIcon,
  CircleStackIcon,
  Cog6ToothIcon,
  CreditCardIcon,
  ArrowLeftStartOnRectangleIcon,
  LockClosedIcon,
  UserIcon,
  UsersIcon,
  TrashIcon
} from '@heroicons/react/24/outline';
import { toast } from 'react-hot-toast';
import teamService, { TeamMember } from '../../services/teamService';

// Add this type near the top of the file
type SettingsTab = 'profile' | 'security' | 'team';

export const Navigation: React.FC = () => {
  const { user, signOut, updateUserAttributes, changePassword } = useAuth();
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [isSubscriptionOpen, setIsSubscriptionOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const modalRef = useRef<HTMLDivElement>(null);
  const [teamMembers, setTeamMembers] = useState<TeamMember[]>([]);
  const [isInviting, setIsInviting] = useState(false);
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
  const [inviteRole, setInviteRole] = useState('Member');
  const inviteModalRef = useRef<HTMLDivElement>(null);
  const [activeDropdownId, setActiveDropdownId] = useState<string | null>(null);
  const [emailList, setEmailList] = useState<string[]>([]);
  const [currentEmail, setCurrentEmail] = useState('');

  // Helper function to check if user is admin
  const isAdmin = () => {
    console.log('Current user role:', user?.role); // Debug log
    return user?.role?.toLowerCase() === 'admin';
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsProfileOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  // Add this useEffect for modal click-outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (isSettingsOpen && 
          modalRef.current && 
          !modalRef.current.contains(event.target as Node)) {
        setIsSettingsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [isSettingsOpen]);

  // Add click outside handler for invite modal
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (inviteModalRef.current && !inviteModalRef.current.contains(event.target as Node)) {
        setIsInviteModalOpen(false);
      }
    };

    if (isInviteModalOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [isInviteModalOpen]);

  // Add click outside handler for team member dropdowns
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (activeDropdownId !== null && !(event.target as Element).closest('.team-member-dropdown')) {
        setActiveDropdownId(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [activeDropdownId]);

  const handleSignOut = async () => {
    try {
      setIsProfileOpen(false); // Close any open menus
      await signOut();
      navigate('/login', { replace: true }); // Use replace to prevent back navigation
    } catch (error) {
      console.error('Sign out error:', error);
      // Still navigate to login on error
      navigate('/login', { replace: true });
    }
  };

  const navItems = [
    {
      path: '/questionnaires',
      label: 'Questionnaire',
      icon: <CubeTransparentIcon className="mr-3 h-5 w-5 flex-shrink-0" />
    },
    {
      path: '/knowledge-base',
      label: 'Knowledge Base',
      icon: <CircleStackIcon className="mr-3 h-5 w-5 flex-shrink-0" />
    }
  ];

  // Add new state variables
  const [formData, setFormData] = useState({
    firstName: user?.givenName || '',
    lastName: user?.familyName || '',
    currentPassword: '',
    newPassword: '',
    confirmPassword: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [notification, setNotification] = useState<{
    type: 'success' | 'error';
    message: string;
  } | null>(null);

  // Add this new state
  const [activeTab, setActiveTab] = useState<SettingsTab>('profile');

  // Add form handlers
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleProfileSubmit = async () => {
    try {
      setIsSubmitting(true);
      
      if (formData.firstName !== user?.givenName || formData.lastName !== user?.familyName) {
        await updateUserAttributes({
          givenName: formData.firstName || '',
          familyName: formData.lastName || ''
        });
        toast.success('Profile updated successfully!');
      }
    } catch (err: any) {
      toast.error(err.message || 'Failed to update profile');
      if (err.message === 'Session expired. Please log in again.') {
        navigate('/login');
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const handlePasswordSubmit = async () => {
    try {
      setIsSubmitting(true);
      
      // Validate passwords match
      if (formData.newPassword !== formData.confirmPassword) {
        throw new Error('New passwords do not match');
      }

      // Validate password requirements
      if (formData.newPassword.length < 8) {
        throw new Error('New password must be at least 8 characters long');
      }

      try {
        await changePassword(formData.currentPassword, formData.newPassword);
        // Only show success and reset form if the API call was successful
        toast.success('Password changed successfully!');
        setFormData(prev => ({
          ...prev,
          currentPassword: '',
          newPassword: '',
          confirmPassword: ''
        }));
      } catch (apiError: any) {
        // Handle API-specific errors
        if (apiError.response?.data?.error) {
          const errorMessage = apiError.response.data.error;
          if (errorMessage.includes('Password must have uppercase characters')) {
            throw new Error('Password must contain at least one uppercase character');
          } else if (errorMessage.includes('Password must have lowercase characters')) {
            throw new Error('Password must contain at least one lowercase character');
          } else if (errorMessage.includes('Password must have numeric characters')) {
            throw new Error('Password must contain at least one number');
          } else if (errorMessage.includes('Password must have symbol characters')) {
            throw new Error('Password must contain at least one special character');
          } else {
            throw new Error(errorMessage);
          }
        }
        throw apiError;
      }
    } catch (err: any) {
      let errorMessage = err.message || 'Failed to change password';
      toast.error(errorMessage);
      
      if (err.message === 'Session expired. Please log in again.') {
        navigate('/login');
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (notification) {
      const timer = setTimeout(() => {
        setNotification(null);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [notification]);

 const handleSubscriptionClick = () => {
    navigate('/subscription');
  };

  // Add this useEffect to fetch team members only when team tab is opened
  useEffect(() => {
    if (activeTab === 'team') {
      fetchTeamMembers();
    }
  }, [activeTab]);

  const fetchTeamMembers = async () => {
    try {
      const members = await teamService.getTeamMembers();
      setTeamMembers(Array.isArray(members) ? members : []);
    } catch (error) {
      console.error('Failed to fetch team members:', error);
      toast.error('Failed to load team members');
      setTeamMembers([]);
    }
  };

  const handleRoleChange = async (userId: string, newRole: string) => {
    console.log('Current user:', user);
    if (!isAdmin()) {
      toast.error('Only admins can change user roles');
      return;
    }

    try {
      await teamService.updateMemberRole(userId, newRole);
      await fetchTeamMembers();
      toast.success('Role updated successfully');
    } catch (error: any) {
      if (error?.response?.data?.error) {
        toast.error(error.response.data.error);
      } else {
        toast.error('Failed to update role');
      }
      console.error('Failed to update role:', error);
    }
  };

  const handleRemoveMember = async (userId: string) => {
    if (!isAdmin()) {
      toast.error('Only admins can remove team members');
      return;
    }
    try {
      await teamService.removeMember(userId);
      await fetchTeamMembers();
      toast.success('Team member removed successfully');
    } catch (error) {
      console.error('Failed to remove member:', error);
      toast.error('Failed to remove team member');
    }
  };

  // Add email validation helper
  const isValidEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' || e.key === ',') {
      e.preventDefault();
      if (currentEmail && isValidEmail(currentEmail)) {
        setEmailList([...emailList, currentEmail]);
        setCurrentEmail('');
      } else if (currentEmail) {
        toast.error('Please enter a valid email address');
      }
    }
  };

  const handleInviteSubmit = async () => {
    // Validate current email and email list
    const emailsToInvite = [...emailList];
    if (currentEmail) {
      if (!isValidEmail(currentEmail)) {
        toast.error('Please enter a valid email address');
        return;
      }
      emailsToInvite.push(currentEmail);
    }
    
    if (emailsToInvite.length === 0) return;
    
    setIsInviting(true);
    try {
      // Invite all emails in sequence with 'Member' role
      for (const email of emailsToInvite) {
        await teamService.inviteMember(email, 'Member');
      }
      await fetchTeamMembers();
      setEmailList([]);
      setCurrentEmail('');
      toast.success('Team members invited successfully');
    } catch (error) {
      console.error('Failed to invite members:', error);
      toast.error('Failed to invite team members');
    } finally {
      setIsInviting(false);
    }
  };

  const removeEmail = (indexToRemove: number) => {
    setEmailList(emailList.filter((_, index) => index !== indexToRemove));
  };

  return (
    <div className="w-56 bg-white h-screen relative border-r border-gray-100">
      {/* Logo Section */}
      <div className="h-16 flex items-center px-6">
        <img src={BasteonLogo} alt="Basteon Logo" className="h-6" />
      </div>

      {/* Navigation Links */}
      <nav className="px-4 pt-8">
        <div className="space-y-1">
          {navItems.map(({ path, label, icon }) => (
            <NavLink key={path} to={path}>
              {({ isActive }) => (
                <div className={`group flex items-center px-2 py-3 text-sm rounded-lg relative
                  transition-all duration-200 ease-in-out
                  ${isActive
                    ? 'text-[#1e8259] font-medium'
                    : 'text-gray-500 font-normal hover:bg-gray-50 hover:text-gray-900'
                  }`}>
                  {icon}
                  <span className="ml-3">{label}</span>
                </div>
              )}
            </NavLink>
          ))}
        </div>
      </nav>

      {/* User Profile Section - Removed border */}
      <div className="absolute bottom-0 w-56">
        <div className="px-2 py-4 relative" ref={dropdownRef}>
          <div 
            className="flex items-center cursor-pointer hover:bg-gray-50 p-1 rounded-lg"
            onClick={() => setIsProfileOpen(!isProfileOpen)}
          >
            <div className="flex-shrink-0">
              <div className="h-8 w-8 rounded-full bg-gray-100 flex items-center justify-center">
                <span className="text-sm font-medium text-gray-600">
                  {user?.givenName?.[0]}{user?.familyName?.[0]}
                </span>
              </div>
            </div>
            <div className="ml-3 flex-grow overflow-hidden">
              <p className="text-sm font-medium text-gray-700 truncate">
                {user?.givenName} {user?.familyName}
              </p>
              <p className="text-xs text-gray-500 truncate">{user?.email}</p>
            </div>
          </div>

          {/* Profile Dropdown */}
          {isProfileOpen && (
            <div className="absolute bottom-full left-0 w-64 mb-2 bg-white rounded-lg shadow-lg border border-gray-200">
              <div className="py-1">
                <button
                  onClick={() => {
                    setIsSettingsOpen(true);
                    setIsProfileOpen(false);
                  }}
                  className="w-full text-left px-4 py-2.5 text-sm text-gray-700 hover:bg-gray-50 flex items-center"
                >
                  <Cog6ToothIcon className="mr-3 h-5 w-5 text-gray-400" />
                  Settings
                </button>

              
                <button
                  onClick={handleSubscriptionClick}
                  className="w-full text-left px-4 py-2.5 text-sm text-gray-700 hover:bg-gray-50 flex items-center"
                >
                  <CreditCardIcon className="mr-3 h-5 w-5 text-gray-400" />
                  Subscription & Billing
                </button>
             

                <div className="border-t border-gray-100 my-1"></div>

                <button
                  onClick={handleSignOut}
                  className="w-full text-left px-4 py-2.5 text-sm text-gray-700 hover:bg-gray-50 flex items-center"
                >
                  <ArrowLeftStartOnRectangleIcon className="mr-3 h-5 w-5 text-gray-400" />
                  Sign Out
                </button>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Settings Modal */}
      {isSettingsOpen && (
        <div className="fixed inset-0 bg-black/25 backdrop-blur-sm z-50 flex items-center justify-center">
          <div ref={modalRef} className="bg-white rounded-xl shadow-xl w-full max-w-4xl mx-4">
            {/* Header - removed border-b */}
            <div className="px-8 py-4 flex items-center justify-between">
              <div className="flex items-center gap-2">
                <Cog6ToothIcon className="w-5 h-5 text-gray-400" />
                <h3 className="text-lg font-medium text-gray-900">Settings</h3>
              </div>
              <button
                onClick={() => setIsSettingsOpen(false)}
                className="text-gray-400 hover:text-gray-500 p-1 rounded-lg hover:bg-gray-50 transition-colors"
              >
                <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                </svg>
              </button>
            </div>

            <div className="flex">
              {/* Tabs - removed border-r */}
              <div className="w-56">
                <nav className="space-y-1 p-4">
                  <button
                    onClick={() => setActiveTab('profile')}
                    className={`w-full flex items-center px-3 py-2 text-sm font-medium rounded-lg ${
                      activeTab === 'profile'
                        ? 'text-[#1e8259] font-medium'
                        : 'text-gray-500 font-normal hover:bg-gray-50 hover:text-gray-900'
                    }`}
                  >
                    <UserIcon className="w-5 h-5 mr-3" />
                    Profile
                  </button>
                  <button
                    onClick={() => setActiveTab('security')}
                    className={`w-full flex items-center px-3 py-2 text-sm font-medium rounded-lg ${
                      activeTab === 'security'
                        ? 'text-[#1e8259] font-medium'
                        : 'text-gray-500 font-normal hover:bg-gray-50 hover:text-gray-900'
                    }`}
                  >
                    <LockClosedIcon className="w-5 h-5 mr-3" />
                    Security
                  </button>
                  <button
                    onClick={() => setActiveTab('team')}
                    className={`w-full flex items-center px-3 py-2 text-sm font-medium rounded-lg ${
                      activeTab === 'team'
                        ? 'text-[#1e8259] font-medium'
                        : 'text-gray-500 font-normal hover:bg-gray-50 hover:text-gray-900'
                    }`}
                  >
                    <UsersIcon className="w-5 h-5 mr-3" />
                    Team Members
                  </button>
                </nav>
              </div>

              {/* Content */}
              <div className="flex-1 px-8 py-6 min-h-[320px] w-[500px]">
                {activeTab === 'profile' && (
                  <div className="space-y-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">First Name</label>
                      <input
                        type="text"
                        name="firstName"
                        placeholder="Enter your first name"
                        value={formData.firstName}
                        onChange={handleInputChange}
                        className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-1 focus:ring-[#1e8259] focus:border-[#1e8259] text-sm"
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">Last Name</label>
                      <input
                        type="text"
                        name="lastName"
                        placeholder="Enter your last name"
                        value={formData.lastName}
                        onChange={handleInputChange}
                        className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-1 focus:ring-[#1e8259] focus:border-[#1e8259] text-sm"
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">Email Address</label>
                      <p className="text-xs text-gray-500 mb-2">Your email address is used for signing in and notifications.</p>
                      <input
                        type="email"
                        placeholder="your@email.com"
                        value={user?.email || ''}
                        disabled
                        className="w-full px-3 py-2 border border-gray-200 rounded-lg bg-gray-50 text-gray-500 text-sm cursor-not-allowed"
                      />
                    </div>
                  </div>
                )}

                {activeTab === 'security' && (
                  <div className="space-y-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">Current Password</label>
                      <input
                        type="password"
                        name="currentPassword"
                        placeholder="Enter your current password"
                        value={formData.currentPassword}
                        onChange={handleInputChange}
                        className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-1 focus:ring-[#1e8259] focus:border-[#1e8259] text-sm"
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">New Password</label>
                      <div className="mt-1">
                        <input
                          type="password"
                          name="newPassword"
                          placeholder="Enter your new password"
                          value={formData.newPassword}
                          onChange={handleInputChange}
                          className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-1 focus:ring-[#1e8259] focus:border-[#1e8259] text-sm"
                        />
                        <p className="mt-2 text-sm text-gray-400">
                          8+ characters with uppercase, lowercase, number & symbol
                        </p>
                      </div>
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">Confirm New Password</label>
                      <input
                        type="password"
                        name="confirmPassword"
                        placeholder="Confirm your new password"
                        value={formData.confirmPassword}
                        onChange={handleInputChange}
                        className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-1 focus:ring-[#1e8259] focus:border-[#1e8259] text-sm"
                      />
                    </div>
                  </div>
                )}

                {activeTab === 'team' && (
                  <div className="space-y-6">
                    <div className="flex items-center gap-3">
                      <div className="flex-1">
                        <div className="flex items-center flex-wrap gap-2 bg-gray-50 border border-gray-200 rounded-lg px-3 py-2">
                          {emailList.map((email, index) => (
                            <span 
                              key={index}
                              className="inline-flex items-center gap-1 px-2 py-1 bg-white border border-gray-200 rounded text-sm text-gray-600"
                            >
                              {email}
                              <button
                                onClick={() => removeEmail(index)}
                                className="text-gray-400 hover:text-gray-600"
                              >
                                ×
                              </button>
                            </span>
                          ))}
                          <input
                            type="email"
                            value={currentEmail}
                            onChange={(e) => setCurrentEmail(e.target.value)}
                            onKeyDown={handleEmailInputKeyDown}
                            placeholder={emailList.length === 0 ? "Enter email addresses separated by comma" : "Add another email"}
                            className="flex-1 min-w-[200px] text-sm bg-transparent border-0 outline-none focus:ring-0 p-0"
                          />
                        </div>
                      </div>
                      <button
                        onClick={handleInviteSubmit}
                        disabled={isInviting || (!currentEmail?.trim() && emailList.length === 0) || (!!currentEmail && !isValidEmail(currentEmail))}
                        className="px-4 py-2 text-sm font-medium text-gray-600 hover:text-gray-900 hover:bg-gray-50 rounded-lg transition-colors disabled:opacity-50 disabled:cursor-not-allowed flex items-center gap-2"
                      >
                        {isInviting ? (
                          <>
                            <div className="animate-spin rounded-full h-4 w-4 border-2 border-gray-300 border-t-gray-600"></div>
                            <span>Inviting...</span>
                          </>
                        ) : (
                          <>
                            <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                            </svg>
                            <span>Invite</span>
                          </>
                        )}
                      </button>
                    </div>

                    <div className="relative space-y-3 max-h-[400px] overflow-y-auto hover:pr-2 transition-[padding] [&::-webkit-scrollbar]:hidden hover:[&::-webkit-scrollbar]:block [&::-webkit-scrollbar]:w-1.5 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:rounded-full [&::-webkit-scrollbar-thumb]:bg-gray-200 [&::-webkit-scrollbar-track]:bg-gray-100/50 hover:[&::-webkit-scrollbar-thumb]:bg-gray-300">
                      {teamMembers.map((member) => (
                        <div key={member.id} className="p-4 bg-white border border-gray-100 rounded-lg hover:border-gray-200 transition-colors">
                          <div className="flex items-center justify-between">
                            <div className="flex items-center space-x-3">
                              <div className="h-8 w-8 rounded-full bg-gray-100 flex items-center justify-center">
                                <span className="text-sm font-medium text-gray-600">
                                  {member.givenName?.[0] || ''}{member.familyName?.[0] || ''}
                                </span>
                              </div>
                              <div>
                                <div className="flex items-center gap-2">
                                  <h4 className="text-sm font-medium text-gray-900">
                                    {member.givenName} {member.familyName}
                                  </h4>
                                  {member.email === user?.email && (
                                    <span className="text-xs text-gray-500">(You)</span>
                                  )}
                                </div>
                                <p className="text-sm text-gray-500">{member.email}</p>
                              </div>
                            </div>
                            <div className="flex items-center gap-4">
                              <select
                                value={member.role || 'Member'}
                                onChange={(e) => handleRoleChange(member.id, e.target.value)}
                                disabled={!isAdmin() || member.email === user?.email}
                                className="text-sm border-gray-300 rounded-md focus:ring-[#1e8259] focus:border-[#1e8259] disabled:bg-gray-50 disabled:cursor-not-allowed"
                              >
                                <option>Admin</option>
                                <option>Member</option>
                              </select>
                              {member.email !== user?.email && isAdmin() && (
                                <div className="relative">
                                  <button
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setActiveDropdownId(activeDropdownId === member.id ? null : member.id);
                                    }}
                                    className="p-2 rounded-lg hover:bg-gray-50 transition-colors team-member-dropdown"
                                  >
                                    <svg className="h-5 w-5 text-gray-400" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                                        d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z" />
                                    </svg>
                                  </button>

                                  {activeDropdownId === member.id && (
                                    <div className="fixed transform translate-x-[-272px] mt-2 w-72 origin-top-right rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-[100] team-member-dropdown">
                                      <div className="py-1">
                                        <button
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            handleRemoveMember(member.id);
                                            setActiveDropdownId(null);
                                          }}
                                          className="group flex w-full px-4 py-3 text-sm items-center hover:bg-gray-50 text-gray-700"
                                        >
                                          <TrashIcon className="w-4 h-4 mr-3 text-gray-400 group-hover:text-gray-500 shrink-0" aria-hidden="true" />
                                          <div className="flex-1 text-left">
                                            <p className="font-medium text-sm leading-5">Remove Member</p>
                                            <p className="text-xs text-gray-500">This action cannot be undone.</p>
                                          </div>
                                        </button>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* Success Message */}
            {notification && notification.type === 'success' && (
              <div className="rounded-lg bg-green-50 p-4 mb-4">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <svg className="h-5 w-5 text-green-400" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                    </svg>
                  </div>
                  <div className="ml-3">
                    <div className="text-sm text-green-800">{notification.message}</div>
                  </div>
                </div>
              </div>
            )}

            {/* Footer - removed border-t */}
            <div className="px-8 py-4 flex justify-end gap-3 rounded-b-xl">
              {activeTab !== 'team' && (
                <>
                  <button
                    onClick={() => setIsSettingsOpen(false)}
                    className="px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 rounded-lg transition-colors"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={activeTab === 'profile' ? handleProfileSubmit : handlePasswordSubmit}
                    disabled={isSubmitting}
                    className="px-4 py-2 text-sm font-medium text-white bg-[#1e8259] hover:bg-[#1e8259]/90 rounded-lg transition-colors disabled:bg-gray-400 disabled:cursor-not-allowed"
                  >
                    {isSubmitting ? 'Saving...' : 'Save Changes'}
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      )}

      {/* Add Subscription Component */}
      {isSubscriptionOpen && <Subscription 
        isOpen={true} 
        onClose={() => setIsSubscriptionOpen(false)} 
      />}

      {/* Simple Modal */}
      {isInviteModalOpen && (
        <div className="fixed inset-0 bg-black/25 backdrop-blur-sm z-50 flex items-center justify-center">
          <div className="bg-white rounded-lg shadow-xl w-full max-w-sm mx-4">
            <div className="flex items-center justify-between px-4 py-3 border-b border-gray-100">
              <h3 className="text-lg font-medium text-gray-900">Add Members</h3>
              <button
                onClick={() => setIsInviteModalOpen(false)}
                className="text-gray-400 hover:text-gray-500"
              >
                <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>

            <div className="p-4">
              <div className="space-y-4">
                <div>
                  <div className="flex flex-wrap gap-2 p-2 border border-gray-300 rounded-lg">
                    {emailList.map((email, index) => (
                      <span 
                        key={index}
                        className="inline-flex items-center gap-1 px-2 py-1 bg-gray-100 rounded text-sm"
                      >
                        {email}
                        <button
                          onClick={() => removeEmail(index)}
                          className="text-gray-400 hover:text-gray-600"
                        >
                          ×
                        </button>
                      </span>
                    ))}
                    <input
                      type="email"
                      value={currentEmail}
                      onChange={(e) => setCurrentEmail(e.target.value)}
                      onKeyDown={handleEmailInputKeyDown}
                      placeholder={emailList.length === 0 ? "Enter email addresses" : ""}
                      className="flex-1 min-w-[150px] border-none focus:outline-none focus:ring-0 text-sm p-1"
                    />
                  </div>
                  <p className="mt-1 text-xs text-gray-500">
                    Press Enter or comma to add multiple emails
                  </p>
                </div>
                <select
                  value={inviteRole}
                  onChange={(e) => setInviteRole(e.target.value)}
                  className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-1 focus:ring-[#1e8259] focus:border-[#1e8259] text-sm"
                >
                  <option value="Admin">Admin</option>
                  <option value="Member">Member</option>
                </select>
              </div>
            </div>

            <div className="flex justify-end gap-2 px-4 py-3 bg-gray-50 rounded-b-lg">
              <button
                onClick={() => {
                  setIsInviteModalOpen(false);
                  setEmailList([]);
                  setCurrentEmail('');
                }}
                className="px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 rounded-lg transition-colors"
              >
                Cancel
              </button>
              <button
                onClick={handleInviteSubmit}
                disabled={isInviting || emailList.length === 0}
                className="px-4 py-2 text-sm font-medium text-white bg-[#1e8259] hover:bg-[#1e8259]/90 rounded-lg transition-colors disabled:opacity-50 disabled:cursor-not-allowed flex items-center gap-2"
              >
                {isInviting ? (
                  <>
                    <div className="animate-spin rounded-full h-4 w-4 border-2 border-white/20 border-t-white"></div>
                    <span>Inviting...</span>
                  </>
                ) : (
                  `Invite ${emailList.length ? `(${emailList.length})` : ''}`
                )}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}; 