import axios, { 
  AxiosInstance, 
  AxiosRequestConfig, 
  AxiosResponse,
  AxiosHeaders 
} from 'axios';

// Import the existing interface
interface RequestConfig extends AxiosRequestConfig {
  skipAuth?: boolean;
}

export class ApiGatewayService {
  private api: AxiosInstance;

  constructor(baseURL: string) {
    this.api = axios.create({
      baseURL: baseURL || '',
      withCredentials: true,
      headers: new AxiosHeaders(),
      timeout: 30000,
      validateStatus: (status) => status < 500
    });

    // Add response interceptor
    this.api.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response?.status === 401 || error.response?.status === 403) {
          // Clear any stored auth state
          if (typeof window !== 'undefined') {
            window.location.href = '/login';
          }
        }
        return Promise.reject(error);
      }
    );
  }

  setCustomTimeout(ms: number) {
    this.api.defaults.timeout = ms;
  }

  async request<T = any>(config: RequestConfig): Promise<AxiosResponse<T>> {
    try {
      if (!config.headers) {
        config.headers = new AxiosHeaders();
      }
      
      return await this.api(config);
    } catch (error: any) {
      if (error.code === 'ERR_NETWORK') {
        console.warn('Network error, server might be unavailable');
        throw new Error('Unable to connect to server');
      }
      throw error;
    }
  }
} 