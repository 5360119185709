import React from 'react';
import { Outlet } from 'react-router-dom';
import { Navigation } from './Navigation';

export const AuthenticatedLayout: React.FC = () => {
  return (
    <div className="flex h-screen bg-white">
      <Navigation />
      <div className="flex-1 overflow-auto p-6">
        <div className="w-full h-full">
          <Outlet />
        </div>
      </div>
    </div>
  );
}; 