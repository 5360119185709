//This service is used to save mapped data to the database and also fetch it for Editing Screen

import { apiGatewayService } from './index';
import { AxiosResponse } from 'axios';

interface MappedQuestion {
  questionText: string;
  answerText?: string | null;
  additionalInfo?: string | null;
  dropdownOptions?: string[] | null;
  excelLocation: {
    question?: { cells: string; worksheetId: string; type: string };
    answer?: { cells: string; worksheetId: string; type: string };
    dropdown?: { cells: string; worksheetId: string; type: string };
    additional?: { cells: string; worksheetId: string; type: string };
  };
}

interface ApiResponse {
  message?: string;
  error?: string;
  data?: any;
}

export interface Question {
  id: string;
  questionText: string;
  answerText?: string;
  dropdownOptions?: string[];
  dropdownValue?: string;
  isApproved: boolean;
  isMarked: boolean;
  order: number;
  questionnaire: {
    id: string;
    fileName: string;
    status: string;
  };
  aiQueryResults?: Record<string, { relevance_score: number }>;
  relevantKnowledgeBase?: Array<{
    id: string;
    title: string;
    description: string;
    sourceName: string;
    createdAt: string;
  }>;
}

interface Questionnaire {
  id: string;
  fileName: string;
  status: string;
}

interface QuestionCounts {
  total: number;
  open: number;
  completed: number;
  marked: number;
}

const confirmMapping = async (questionnaireId: string, questions: MappedQuestion[]) => {
  try {
    const response: AxiosResponse<ApiResponse> = await apiGatewayService.request({
      method: 'POST',
      url: `/api/questionnaires/${questionnaireId}/confirm-mapping`,
      data: { questions }
    });

    if (response.status >= 400) {
      throw new Error(response.data.error || 'Failed to confirm mapping');
    }

    return response.data;
  } catch (error) {
    console.error('Error confirming mapping:', error);
    throw error;
  }
};

const getQuestions = async (questionnaireId: string, cursor?: string): Promise<{ questions: Question[], nextCursor?: string }> => {
  try {
    const response = await apiGatewayService.request({
      method: 'GET',
      url: `/api/questionnaires/${questionnaireId}/questions`,
      params: {
        include: 'questionnaire',
        cursor
      }
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching questions:', error);
    throw error;
  }
};

const updateQuestion = async (
  questionnaireId: string,
  questionId: string,
  data: {
    answerText?: string;
    dropdownValue?: string;
    aiQueryResults?: Record<string, { relevance_score: number }>;
  }
): Promise<Question> => {
  try {
    const response = await apiGatewayService.request({
      method: 'PATCH',
      url: `/api/questionnaires/${questionnaireId}/questions/${questionId}`,
      data
    });

    return response.data;
  } catch (error) {
    console.error('Error updating question:', error);
    throw error;
  }
};

const getQuestionnaire = async (questionnaireId: string): Promise<Questionnaire> => {
  try {
    const response = await apiGatewayService.request({
      method: 'GET',
      url: `/api/questionnaires/${questionnaireId}`,
    });

    if (response.status >= 400) {
      throw new Error(response.data.error || 'Failed to fetch questionnaire');
    }

    return response.data;
  } catch (error) {
    console.error('Error fetching questionnaire:', error);
    throw error;
  }
};

const getQuestionCounts = async (questionnaireId: string): Promise<QuestionCounts> => {
  try {
    const response = await apiGatewayService.request({
      method: 'GET',
      url: `/api/questionnaires/${questionnaireId}/questions/counts`,
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching question counts:', error);
    throw error;
  }
};

export const questionService = {
  confirmMapping,
  getQuestions,
  updateQuestion,
  getQuestionnaire,
  getQuestionCounts
};