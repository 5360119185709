import { apiGatewayService } from './index';
import { AxiosResponse } from 'axios';

interface StripeCheckoutResponse {
  url: string;
}

interface SubscriptionStatus {
  status: string;
  currentPeriodEnd?: string;
  cancelAtPeriodEnd?: boolean;
}

class StripeService {
  /**
   * Creates a Stripe checkout session for subscription
   * @returns Promise with the checkout URL
   */
  async createCheckoutSession(): Promise<StripeCheckoutResponse> {
    try {
      const response: AxiosResponse<StripeCheckoutResponse> = await apiGatewayService.request({
        method: 'POST',
        url: '/api/subscription/create-payment-link'
      });

      return response.data;
    } catch (error) {
      console.error('Error creating checkout session:', error);
      throw new Error('Failed to create checkout session');
    }
  }

  /**
   * Retrieves current subscription status
   * @returns Promise with subscription details
   */
  async getSubscriptionStatus(): Promise<SubscriptionStatus> {
    try {
      const response: AxiosResponse<SubscriptionStatus> = await apiGatewayService.request({
        method: 'GET',
        url: '/api/subscription/status'
      });

      return response.data;
    } catch (error) {
      console.error('Error fetching subscription status:', error);
      throw new Error('Failed to fetch subscription status');
    }
  }

  /**
   * Creates customer portal session for managing subscription
   * @returns Promise with the portal URL
   */
  async createPortalSession(): Promise<StripeCheckoutResponse> {
    try {
      const response: AxiosResponse<StripeCheckoutResponse> = await apiGatewayService.request({
        method: 'POST',
        url: '/api/subscription/create-portal-session'
      });

      return response.data;
    } catch (error) {
      console.error('Error creating portal session:', error);
      throw new Error('Failed to create customer portal session');
    }
  }
}

// Export a singleton instance
export const stripeService = new StripeService(); 