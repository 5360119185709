import React, { useState } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import BasteonLogo from '../../assets/basteon-logo.svg';

export const SignInForm: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirm, setNewPasswordConfirm] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showNewPasswordField, setShowNewPasswordField] = useState(false);
  const { signIn } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (isLoading) return;
    
    setError('');
    setIsLoading(true);

    try {
      if (!email || !password) {
        throw new Error('Please enter both email and password');
      }

      if (showNewPasswordField && (!firstName || !lastName)) {
        throw new Error('Please enter your first and last name');
      }

      if (showNewPasswordField && newPassword !== newPasswordConfirm) {
        throw new Error('New passwords do not match');
      }

      if (showNewPasswordField && !acceptedTerms) {
        throw new Error('You must accept the Terms of Service and Privacy Policy');
      }

      const result = await signIn(
        email, 
        password, 
        showNewPasswordField ? newPassword : undefined,
        showNewPasswordField ? { firstName, lastName } : undefined
      );
      
      if (result?.error === 'NEW_PASSWORD_REQUIRED') {
        setShowNewPasswordField(true);
        setError('Please set a new password and provide your name');
        setIsLoading(false);
        return;
      }

      if (result?.error && result?.pendingApproval) {
        setError(result.error);
        setIsLoading(false);
        return;
      }

      const from = location.state?.from?.pathname || '/';
      navigate(from, { replace: true });
    } catch (err: any) {
      console.error('Login error:', err);
      if (err.response?.data?.error === 'Authentication failed' || err.message?.includes('Invalid login response')) {
        setError('Incorrect email or password. Please try again.');
      } else {
        setError(err.message || 'An error occurred while signing in. Please try again.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50/50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full">
        <div className="text-center mb-8">
          <div className="flex justify-center mb-10">
            <img 
              src={BasteonLogo} 
              alt="Basteon" 
              className="h-8 w-auto"
            />
          </div>
          <h2 className="text-3xl font-semibold text-gray-900">Welcome</h2>
          <p className="mt-2 text-sm text-gray-600">Sign in to your account to continue</p>
        </div>

        <div className="bg-white shadow-sm border border-gray-200 rounded-xl p-8">
          <form className="space-y-6" onSubmit={handleSubmit}>
            {error && (
              <div className="rounded-lg bg-red-50 p-4">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <svg className="h-5 w-5 text-red-400" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                        d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                  </div>
                  <div className="ml-3">
                    <div className="text-sm text-red-800">{error}</div>
                  </div>
                </div>
              </div>
            )}

            <div className="space-y-5">
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                  Email address
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    type="email"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="appearance-none block w-full px-3 py-2.5 border border-gray-300 rounded-lg shadow-sm 
                      placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-[#1e8259] focus:border-[#1e8259] sm:text-sm"
                    placeholder="Enter your email"
                  />
                </div>
              </div>

              <div>
                <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                  {showNewPasswordField ? 'Temporary Password' : 'Password'}
                </label>
                <div className="mt-1">
                  <input
                    id="password"
                    type="password"
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="appearance-none block w-full px-3 py-2.5 border border-gray-300 rounded-lg shadow-sm 
                      placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-[#1e8259] focus:border-[#1e8259] sm:text-sm"
                    placeholder={showNewPasswordField ? "Enter temporary password" : "Enter your password"}
                  />
                </div>
              </div>

              {showNewPasswordField && (
                <>
                  <div>
                    <label htmlFor="firstName" className="block text-sm font-medium text-gray-700">
                      First Name
                    </label>
                    <div className="mt-1">
                      <input
                        id="firstName"
                        type="text"
                        required
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        className="appearance-none block w-full px-3 py-2.5 border border-gray-300 rounded-lg shadow-sm 
                          placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-[#1e8259] focus:border-[#1e8259] sm:text-sm"
                        placeholder="Enter your first name"
                      />
                    </div>
                  </div>

                  <div>
                    <label htmlFor="lastName" className="block text-sm font-medium text-gray-700">
                      Last Name
                    </label>
                    <div className="mt-1">
                      <input
                        id="lastName"
                        type="text"
                        required
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        className="appearance-none block w-full px-3 py-2.5 border border-gray-300 rounded-lg shadow-sm 
                          placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-[#1e8259] focus:border-[#1e8259] sm:text-sm"
                        placeholder="Enter your last name"
                      />
                    </div>
                  </div>

                  <div>
                    <label htmlFor="newPassword" className="block text-sm font-medium text-gray-700">
                      New Password
                    </label>
                    <div className="mt-1">
                      <input
                        id="newPassword"
                        type="password"
                        required
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        className="appearance-none block w-full px-3 py-2.5 border border-gray-300 rounded-lg shadow-sm 
                          placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-[#1e8259] focus:border-[#1e8259] sm:text-sm"
                        placeholder="Create new password"
                      />
                    </div>
                    <p className="mt-2 text-sm text-gray-500">
                      Password must contain at least 8 characters, including uppercase, lowercase, numbers, and special characters.
                    </p>
                  </div>

                  <div>
                    <label htmlFor="newPasswordConfirm" className="block text-sm font-medium text-gray-700">
                      Confirm New Password
                    </label>
                    <div className="mt-1">
                      <input
                        id="newPasswordConfirm"
                        type="password"
                        required
                        value={newPasswordConfirm}
                        onChange={(e) => setNewPasswordConfirm(e.target.value)}
                        className="appearance-none block w-full px-3 py-2.5 border border-gray-300 rounded-lg shadow-sm 
                          placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-[#1e8259] focus:border-[#1e8259] sm:text-sm"
                        placeholder="Confirm new password"
                      />
                    </div>
                  </div>

                  <div>
                    <div className="mt-4">
                      <div className="relative flex items-start">
                        <div className="flex h-5 items-center">
                          <input
                            id="acceptedTerms"
                            name="acceptedTerms"
                            type="checkbox"
                            checked={acceptedTerms}
                            onChange={(e) => setAcceptedTerms(e.target.checked)}
                            className="h-4 w-4 rounded border-gray-300 text-[#1e8259] focus:ring-[#1e8259]"
                            required
                          />
                        </div>
                        <div className="ml-3 text-sm">
                          <label htmlFor="acceptedTerms" className="text-gray-600">
                            I agree to the{' '}
                            <a href="https://www.basteon.com/terms" target="_blank" rel="noopener noreferrer" className="text-[#1e8259] hover:text-[#1e8259]/80">
                              Terms of Service
                            </a>{' '}
                            and{' '}
                            <a href="https://www.basteon.com/privacy-policy" target="_blank" rel="noopener noreferrer" className="text-[#1e8259] hover:text-[#1e8259]/80">
                              Privacy Policy
                            </a>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>

            {!showNewPasswordField && (
              <div className="flex items-center justify-end">
                <div className="text-sm">
                  <Link 
                    to="/reset-password" 
                    className="font-medium text-[#1e8259] hover:text-[#1e8259]/80"
                  >
                    Forgot your password?
                  </Link>
                </div>
              </div>
            )}

            <div>
              <button
                type="submit"
                disabled={isLoading || (showNewPasswordField && !newPassword)}
                className={`w-full flex justify-center py-2.5 px-4 border border-transparent rounded-lg shadow-sm text-sm 
                  font-medium text-white transition-all duration-200 ${
                    isLoading
                      ? 'bg-gray-400 cursor-not-allowed'
                      : 'bg-[#1e8259] hover:bg-[#1e8259]/90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#1e8259]'
                  }`}
              >
                {isLoading ? (
                  <span className="flex items-center">
                    <svg className="animate-spin -ml-1 mr-3 h-4 w-4 text-white" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      />
                    </svg>
                    Signing in...
                  </span>
                ) : (
                  showNewPasswordField ? 'Set New Password' : 'Sign in'
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}; 