import { apiGatewayService } from './index';
// import { IWorkbookData } from '@univerjs/core';

interface QuestionnaireResponse {
  id: string;
  fileKey: string;
}

interface ExportResponse {
  downloadUrl: string;
}

interface PresignedUrlResponse {
  uploadUrl: string;
  questionnaireId: string;
}

const questionnaireService = {
  uploadFile: async (file: File): Promise<QuestionnaireResponse> => {
    try {
      const presignedResponse = await apiGatewayService.request<PresignedUrlResponse>({
        method: 'POST',
        url: '/api/questionnaires/presigned-upload',
        data: {
          fileName: file.name,
          contentType: file.type
        }
      });

      if (!presignedResponse.data?.uploadUrl) {
        throw new Error('Failed to get upload URL');
      }

      await fetch(presignedResponse.data.uploadUrl, {
        method: 'PUT',
        body: file,
        headers: {
          'Content-Type': file.type
        }
      });

      const confirmResponse = await apiGatewayService.request<QuestionnaireResponse>({
        method: 'POST',
        url: `/api/questionnaires/confirm-upload/${presignedResponse.data.questionnaireId}`
      });

      if (!confirmResponse.data) {
        throw new Error('Failed to confirm upload');
      }

      return confirmResponse.data;
    } catch (error) {
      console.error('Upload failed:', error);
      throw error;
    }
  },

  getQuestionnaires: async () => {
    try {
      const response = await apiGatewayService.request({
        method: 'GET',
        url: '/api/questionnaires'
      });

      return response.data;
    } catch (error) {
      console.error('Failed to fetch questionnaires:', error);
      throw error;
    }
  },

  getWorkbook: async (questionnaireId: string) => {
    try {
      const response = await apiGatewayService.request({
        method: 'GET',
        url: `/api/questionnaires/${questionnaireId}/workbook`,
        timeout: 30000  // Increase timeout to 30 seconds for large Excel files
      });

      return response.data;
    } catch (error) {
      console.error('Failed to fetch workbook:', error);
      throw error;
    }
  },

  exportQuestionnaire: async (questionnaireId: string): Promise<ExportResponse> => {
    try {
      const response = await apiGatewayService.request<ExportResponse>({
        method: 'POST',
        url: `/api/questionnaires/${questionnaireId}/export`,
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (!response.data?.downloadUrl) {
        throw new Error('No download URL received');
      }

      return response.data;
    } catch (error) {
      console.error('Export failed:', error);
      throw error;
    }
  },


//Delete Questionnaire from Questionnaire Overview (we delete db entries, but move the file to deleted folder in S3 )

  deleteQuestionnaire: async (id: string): Promise<void> => {
    try {
      const response = await apiGatewayService.request({
        method: 'DELETE',
        url: `/api/questionnaires/${id}`,
      });

      if (response.status >= 400) {
        throw new Error(response.data.error || 'Failed to delete questionnaire');
      }
    } catch (error) {
      console.error('Delete failed:', error);
      throw error;
    }
  },
};

export default questionnaireService;