import { ApiGatewayService } from './index';
import { CsrfService } from './CsrfService';
import { AuthSession, User } from '../types/authentication';

interface LoginResponse {
  user: User;
  error?: string;
  pendingApproval?: boolean;
}

interface AuthResult {
  session?: AuthSession;
  error?: string;
  pendingApproval?: boolean;
}

export class AuthService {
  private sessionCheckInterval: NodeJS.Timeout | null = null;
  private signupEmail: string | null = null;

  constructor(
    private apiGateway: ApiGatewayService,
    private csrfService: CsrfService
  ) {
    this.initializeSessionCheck();
  }

  private initializeSessionCheck() {
    if (this.sessionCheckInterval) {
      clearInterval(this.sessionCheckInterval);
    }

    // Only start interval if we're in a browser environment
    if (typeof window !== 'undefined') {
      this.sessionCheckInterval = setInterval(async () => {
        try {
          const response = await this.apiGateway.request<{ user: User }>({
            method: 'GET',
            url: '/api/auth/session'
          });

          if (!response.data?.user) {
            throw new Error('Invalid session response');
          }
        } catch (error: any) {
          console.error('Session check error:', error);
          if (error.response?.status === 401 || error.response?.status === 403) {
            window.location.href = '/login';
          }
        }
      }, 15 * 60 * 1000); // Back to 15 minutes
    }
  }

  async getCurrentSession(): Promise<AuthSession | null> {
    try {
      const response = await this.apiGateway.request<{ user: User }>({
        method: 'GET',
        url: '/api/auth/session'
      });

      if (!response.data?.user) {
        return null;
      }

      return {
        user: response.data.user
      };
    } catch (error) {
      console.error('Error getting current session:', error);
      return null;
    }
  }

  async signIn(
    email: string, 
    password: string, 
    newPassword?: string,
    userInfo?: { firstName: string; lastName: string }
  ): Promise<AuthResult> {
    try {
      const headers = await this.csrfService.addTokenToHeaders();

      const response = await this.apiGateway.request<LoginResponse>({
        method: 'POST',
        url: '/api/auth/login',
        headers,
        data: { 
          email, 
          password, 
          newPassword,
          ...(userInfo && {
            givenName: userInfo.firstName,
            familyName: userInfo.lastName
          })
        }
      });

      // Handle NEW_PASSWORD_REQUIRED challenge
      if (response.data.error === 'NEW_PASSWORD_REQUIRED') {
        return {
          error: 'NEW_PASSWORD_REQUIRED'
        };
      }

      // Handle pending approval
      if (response.data.error && response.data.pendingApproval) {
        return {
          error: response.data.error,
          pendingApproval: true
        };
      }

      if (!response.data?.user) {
        console.warn('Invalid login response:', response);
        return { error: 'Invalid login response' };
      }

      return {
        session: {
          user: {
            email: response.data.user.email,
            givenName: response.data.user.givenName,
            familyName: response.data.user.familyName,
            organizationId: response.data.user.organizationId,
            role: response.data.user.role
          }
        }
      };
    } catch (error: any) {
      console.error('Login error:', error);
      if (error.response?.data?.error) {
        return {
          error: error.response.data.error,
          pendingApproval: error.response.data.pendingApproval
        };
      }
      throw error;
    }
  }

  async signOut(): Promise<void> {
    try {
      // Get CSRF headers for the request
      const headers = await this.csrfService.addTokenToHeaders();

      await this.apiGateway.request({
        method: 'POST',
        url: '/api/auth/signout',
        headers
      });
      
      if (this.sessionCheckInterval) {
        clearInterval(this.sessionCheckInterval);
        this.sessionCheckInterval = null;
      }
    } catch (error) {
      console.error('Signout error:', error);
      throw error;
    }
  }

  async signUp(email: string, password: string, userData: {
    givenName: string;
    familyName: string;
    organizationName: string;
  }): Promise<void> {
    try {
      const headers = await this.csrfService.addTokenToHeaders();

      await this.apiGateway.request({
        method: 'POST',
        url: '/api/auth/signup',
        headers,
        data: {
          email,
          password,
          ...userData
        }
      });

      // Redirect to verification page with email parameter
      window.location.href = `/verify-email?email=${encodeURIComponent(email)}`;
    } catch (error: any) {
      console.error('Signup error:', error);
      throw error;
    }
  }

  async verifyEmail(email: string, code: string): Promise<void> {
    if (!email || !code) {
      throw new Error('Email and verification code are required');
    }

    try {
      const headers = await this.csrfService.addTokenToHeaders();

      await this.apiGateway.request({
        method: 'POST',
        url: '/api/auth/verify-email',
        headers,
        data: { 
          email: email.trim(),
          code: code.trim()
        }
      });
    } catch (error: any) {
      if (error.response?.status === 429) {
        throw new Error('Too many verification attempts. Please try again later.');
      }
      if (error.response?.status === 400) {
        throw new Error('Invalid verification code. Please check and try again.');
      }
      console.error('Email verification error:', error);
      throw error;
    }
  }

  async resendVerificationCode(email: string): Promise<void> {
    if (!email) {
      throw new Error('Email is required');
    }

    try {
      const headers = await this.csrfService.addTokenToHeaders();

      await this.apiGateway.request({
        method: 'POST',
        url: '/api/auth/resend-code',
        headers,
        data: { email: email.trim() }
      });
    } catch (error: any) {
      if (error.response?.status === 429) {
        throw new Error('Please wait before requesting another code.');
      }
      console.error('Resend verification code error:', error);
      throw error;
    }
  }

  async forgotPassword(email: string): Promise<void> {
    try {
      // Get CSRF headers for the request
      const headers = await this.csrfService.addTokenToHeaders();

      await this.apiGateway.request({
        method: 'POST',
        url: '/api/auth/forgot-password',
        headers,
        data: { email }
      });
    } catch (error: any) {
      console.error('Forgot password error:', error);
      throw error;
    }
  }

  async confirmPasswordReset(email: string, code: string, newPassword: string): Promise<void> {
    try {
      // Get CSRF headers for the request
      const headers = await this.csrfService.addTokenToHeaders();

      await this.apiGateway.request({
        method: 'POST',
        url: '/api/auth/confirm-password-reset',
        headers,
        data: { 
          email,
          code,
          newPassword
        }
      });
    } catch (error: any) {
      console.error('Password reset error:', error);
      if (error.response?.data?.error) {
        throw new Error(error.response.data.error);
      }
      throw new Error('Failed to reset password. Please try again.');
    }
  }

  async updateUserAttributes(attributes: { [key: string]: string }): Promise<void> {
    try {
      // Get CSRF headers for the request
      const headers = await this.csrfService.addTokenToHeaders();

      await this.apiGateway.request({
        method: 'PUT',
        url: '/api/users/profile',
        headers,
        data: attributes
      });
    } catch (error: any) {
      console.error('Update attributes error:', error);
      throw error;
    }
  }

  async changePassword(currentPassword: string, newPassword: string): Promise<void> {
    try {
      // Get CSRF headers for the request
      const headers = await this.csrfService.addTokenToHeaders();

      await this.apiGateway.request({
        method: 'POST',
        url: '/api/auth/change-password',
        headers,
        data: {
          currentPassword,
          newPassword
        }
      });
    } catch (error: any) {
      console.error('Change password error:', error);
      throw error;
    }
  }

  getSignupEmail(): string | null {
    return this.signupEmail;
  }
} 