import React, { useState } from 'react';
import { knowledgeBaseService } from '../services/knowledgebaseService';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useReactTable, getCoreRowModel, getPaginationRowModel, flexRender, createColumnHelper } from '@tanstack/react-table';
import toast from 'react-hot-toast';
import { aiService } from '../services/aiService';
import { useNavigate } from 'react-router-dom';

export const KnowledgeBase: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [isUploading, setIsUploading] = useState(false);

  // Fetch knowledge base items
  const { 
    data: knowledgeBaseItems = [], 
    error,
    isLoading 
  } = useQuery({
    queryKey: ['knowledgeBase'],
    queryFn: knowledgeBaseService.getKnowledgeBaseItems,
    staleTime: 0
  });

  // Delete mutation
  const deleteMutation = useMutation({
    mutationFn: knowledgeBaseService.deleteKnowledgeBaseItem,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['knowledgeBase'] });
      
    },
    onError: (error) => {
      toast.error('Failed to delete item');
      console.error('Delete error:', error);
    }
  });

  // Memoize filtered items to prevent unnecessary recalculations
  const filteredItems = React.useMemo(() => 
    knowledgeBaseItems.filter(item => {
      const matchesSearch = 
        item.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.description.toLowerCase().includes(searchTerm.toLowerCase());
      return matchesSearch;
    }),
    [knowledgeBaseItems, searchTerm]
  );

  const handleUpload = async (file: File) => {
    try {
      setIsUploading(true);
      
      // Get presigned URL
      const { uploadUrl, questionnaireId } = await knowledgeBaseService.getPresignedKBUrl(
        file.name, 
        file.type
      );

      // Perform the actual upload to S3
      await fetch(uploadUrl, {
        method: 'PUT',
        body: file,
        headers: {
          'Content-Type': file.type
        }
      });

      // Update navigation path to match route
      navigate(`/kb-mapping/${questionnaireId}`);
      
    } catch (error) {
      console.error('Upload error:', error);
      toast.error('Failed to upload file');
    } finally {
      setIsUploading(false);
    }
  };

  const handleImportQuestionnaire = () => {
    if (isUploading) return;
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = '.xlsx';
    input.onchange = (e) => {
      const file = (e.target as HTMLInputElement).files?.[0];
      if (file) handleUpload(file);
    };
    input.click();
  };

  // Update the row selection state type
  const [rowSelection, setRowSelection] = useState<Record<string, boolean>>({});

  // Define columns
  const columnHelper = createColumnHelper<any>();
  const columns = React.useMemo(
    () => [
      columnHelper.display({
        id: 'select',
        header: ({ table }) => (
          <input
            type="checkbox"
            checked={table.getIsAllRowsSelected()}
            onChange={table.getToggleAllRowsSelectedHandler()}
            className="rounded border-gray-300 text-[#1e8259] focus:ring-[#1e8259]"
          />
        ),
        cell: ({ row }) => (
          <input
            type="checkbox"
            checked={row.getIsSelected()}
            onChange={row.getToggleSelectedHandler()}
            className="rounded border-gray-300 text-[#1e8259] focus:ring-[#1e8259]"
          />
        ),
      }),
      columnHelper.accessor('title', {
        header: 'Control',
        cell: (info) => (
          <div className="min-w-[400px] group">
            <div className="text-sm font-medium text-gray-900">{info.getValue()}</div>
            <div className="text-sm text-gray-500 whitespace-pre-line line-clamp-2 group-hover:line-clamp-none">
              {info.row.original.description}
            </div>
          </div>
        ),
      }),
      columnHelper.accessor('sourceName', {
        header: 'Source',
        cell: (info) => <span className="text-sm text-gray-500 min-w-[100px] block">{info.getValue()}</span>,
      }),
      columnHelper.accessor('updatedAt', {
        header: 'Last Updated',
        cell: (info) => (
          <span className="text-sm text-gray-500 min-w-[90px] block">
            {new Date(info.getValue()).toLocaleDateString('en-US', {
              month: 'short',
              day: 'numeric',
              year: 'numeric'
            })}
          </span>
        ),
      }),
    ],
    []
  );

  // Memoize table instance
  const table = useReactTable({
    data: filteredItems,
    columns,
    state: {
      rowSelection,
    },
    enableRowSelection: true,
    enableMultiRowSelection: true,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: {
      pagination: {
        pageSize: 10,
      },
    },
  });

  // Update the bulk delete handler to use the correct row indices
  const handleBulkDelete = async () => {
    const selectedIds = Object.keys(rowSelection).map((index) => {
      const row = table.getRowModel().rowsById[index];
      return row?.original.id;
    }).filter(Boolean);
    
    try {
      // First delete from KB
      await Promise.all(selectedIds.map((id) => deleteMutation.mutateAsync(id)));

      // Then delete from AI service - send as array, not comma-separated string
      try {
        const aiResponse = await aiService.deleteDocument(selectedIds); // Send array directly
        console.log('AI Bulk Delete Response:', aiResponse);

        if (aiResponse.deleted_count > 0) {
          setRowSelection({});
          toast.success(`${aiResponse.deleted_count} items deleted successfully`);
        } else {
          console.warn('AI deletion returned 0 count');
          throw new Error('AI deletion failed - no items deleted');
        }
      } catch (aiError) {
        console.error('AI deletion failed:', aiError);
        setRowSelection({});
        toast.success('Items deleted from Knowledge Base');
        toast.error('AI system update failed - will retry later');
      }
    } catch (error) {
      console.error('Error during bulk delete:', error);
      toast.error('Failed to delete some items');
    }
  };

  if (error) {
    return <div className="text-center py-12 text-red-500">
      Failed to load knowledge base items
    </div>;
  }

  return (
    <>
      {/* Adjust the search header alignment */}
      <div className="fixed top-0 left-64 right-0 bg-white z-30">
        <div className="px-6">
          <div className="max-w-7xl mx-auto">
            <div className="flex h-16 items-center">
              <div className="flex flex-1 items-center">
                <div className="relative flex-1 max-w-2xl">
                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <svg 
                      className="h-4 w-4 text-gray-400" 
                      fill="none" 
                      viewBox="0 0 24 24" 
                      stroke="currentColor"
                    >
                      <path 
                        strokeLinecap="round" 
                        strokeLinejoin="round" 
                        strokeWidth={1.5} 
                        d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" 
                      />
                    </svg>
                  </div>
                  <input
                    type="text"
                    placeholder="Search"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="block w-full py-1.5 pl-10 pr-4 text-sm text-gray-900 
                      placeholder:text-gray-400 focus:outline-none focus:ring-0
                      bg-gray-50/50 focus:bg-white transition-colors"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Add spacing for fixed header */}
      <div className="h-16" />

      {/* Main Content */}
      <div className="max-w-7xl mx-auto">
        <div className="bg-white rounded-lg px-4 sm:px-6 lg:px-8">
          {/* Header Section - Updated spacing to match QuestionnaireOverview */}
          <div className="pt-1 pb-2">
            <div className="flex justify-between items-center mb-3">
              <div>
                <h1 className="text-xl font-medium text-gray-900">Knowledge Base</h1>
                <p className="mt-0.5 text-sm text-gray-500">
                  Browse and search through the AI knowledge sources used in the system
                </p>
              </div>
              <button
                onClick={handleImportQuestionnaire}
                className="ml-3 inline-flex items-center px-3 py-2 border border-transparent 
                  text-sm font-medium rounded-md shadow-sm text-white bg-[#1e8259] 
                  hover:bg-[#1e8259]/90 focus:outline-none focus:ring-2 focus:ring-offset-2 
                  focus:ring-[#1e8259]"
              >
                <svg 
                  className="w-4 h-4 mr-2 text-current" 
                  xmlns="http://www.w3.org/2000/svg" 
                  fill="none" 
                  viewBox="0 0 24 24" 
                  strokeWidth={2} 
                  stroke="currentColor"
                >
                  <path 
                    strokeLinecap="round" 
                    strokeLinejoin="round" 
                    d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5"
                  />
                </svg>
                Import Questionnaire
              </button>
            </div>
          </div>

          {/* Updated Table View */}
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                {table.getHeaderGroups().map(headerGroup => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map(header => (
                      <th key={header.id} className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        {flexRender(header.column.columnDef.header, header.getContext())}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {!isLoading && (table.getRowModel().rows.length > 0 ? (
                  table.getRowModel().rows.map(row => (
                    <tr key={row.id} className="hover:bg-gray-50">
                      {row.getVisibleCells().map(cell => (
                        <td key={cell.id} className="px-6 py-4">
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                      ))}
                    </tr>
                  ))
                ) : (
                  // Empty state inside table - only shown when data is loaded and empty
                  <tr>
                    <td colSpan={columns.length} className="px-6 py-48 text-center">
                      <div className="max-w-sm mx-auto">
                        <div className="inline-flex items-center justify-center w-12 h-12 rounded-full bg-gray-100 mb-4">
                          <svg 
                            className="h-6 w-6 text-gray-600" 
                            xmlns="http://www.w3.org/2000/svg" 
                            fill="none" 
                            viewBox="0 0 24 24" 
                            strokeWidth={1.5} 
                            stroke="currentColor"
                          >
                            <path 
                              strokeLinecap="round" 
                              strokeLinejoin="round" 
                              d="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125"
                            />
                          </svg>
                        </div>
                        <h3 className="text-base font-medium text-gray-900">Your Knowledge Base is Empty</h3>
                        <p className="mt-1 text-sm text-gray-500">
                          Upload your completed security questionnaires to build an AI-powered knowledge base
                        </p>
                        <button
                          onClick={handleImportQuestionnaire}
                          className="mt-4 inline-flex items-center px-3 py-2 border border-gray-300 
                            text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                        >
                          <svg 
                            className="w-4 h-4 mr-2" 
                            xmlns="http://www.w3.org/2000/svg" 
                            fill="none" 
                            viewBox="0 0 24 24" 
                            strokeWidth={1.5} 
                            stroke="currentColor"
                          >
                            <path 
                              strokeLinecap="round" 
                              strokeLinejoin="round" 
                              d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5"
                            />
                          </svg>
                          Import Questionnaire
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            
            {/* Pagination */}
            <div className="px-6 py-3 flex items-center justify-between border-t border-gray-200">
              <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                <div className="flex items-center gap-4">
                  <p className="text-sm text-gray-700">
                    Showing{' '}
                    <span className="font-medium">{table.getState().pagination.pageIndex * table.getState().pagination.pageSize + 1}</span>
                    {' '}-{' '}
                    <span className="font-medium">
                      {Math.min((table.getState().pagination.pageIndex + 1) * table.getState().pagination.pageSize, filteredItems.length)}
                    </span>
                    {' '}of{' '}
                    <span className="font-medium">{filteredItems.length}</span>
                    {' '}results
                  </p>
                  <select
                    value={table.getState().pagination.pageSize}
                    onChange={e => {
                      table.setPageSize(Number(e.target.value));
                    }}
                    className="text-sm border-gray-300 rounded-md focus:ring-[#1e8259] focus:border-[#1e8259]"
                  >
                    {[10, 20, 50, 100].map(pageSize => (
                      <option key={pageSize} value={pageSize}>
                        Show {pageSize}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px">
                    <button
                      onClick={() => table.previousPage()}
                      disabled={!table.getCanPreviousPage()}
                      className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-gray-25 text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:bg-gray-100 disabled:cursor-not-allowed"
                    >
                      Previous
                    </button>
                    
                    {/* Page Numbers */}
                    {Array.from(
                      { length: table.getPageCount() },
                      (_, index) => {
                        if (
                          index === 0 ||
                          index === table.getPageCount() - 1 ||
                          Math.abs(index - table.getState().pagination.pageIndex) <= 1
                        ) {
                          return (
                            <button
                              key={index}
                              onClick={() => table.setPageIndex(index)}
                              className={`relative inline-flex items-center px-2 py-2 border text-sm font-medium text-gray-500
                                ${index === table.getState().pagination.pageIndex
                                  ? 'z-10 bg-gray-50 border-gray-300 font-semibold'
                                  : 'bg-white border-gray-300 hover:bg-gray-50'
                                }`}
                            >
                              {index + 1}
                            </button>
                          );
                        }
                        // Show ellipsis if there's a gap
                        if (
                          Math.abs(index - table.getState().pagination.pageIndex) === 2
                        ) {
                          return (
                            <span
                              key={index}
                              className="relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500"
                            >
                              ...
                            </span>
                          );
                        }
                        return null;
                      }
                    )}

                    <button
                      onClick={() => table.nextPage()}
                      disabled={!table.getCanNextPage()}
                      className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-gray-25 text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:bg-gray-100 disabled:cursor-not-allowed"
                    >
                      Next
                    </button>
                  </nav>
                </div>
              </div>
            </div>
          </div>

          {/* Bulk Delete Action Bar */}
          {Object.keys(rowSelection).length > 0 && (
            <div className="fixed bottom-4 left-1/2 -translate-x-1/2 bg-white rounded-lg shadow-lg border border-gray-200 p-4 flex items-center gap-4">
              <span className="text-sm text-gray-600">
                {Object.keys(rowSelection).length} items selected
              </span>
              <button
                onClick={handleBulkDelete}
                className="px-4 py-2 text-sm text-white bg-red-500 hover:bg-red-600 rounded-md transition-colors"
              >
                Delete
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
