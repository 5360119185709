import { useNavigate, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { stripeService } from '../services/stripeService';

// Cache duration in milliseconds (5 minutes)
const CACHE_DURATION = 5 * 60 * 1000;

interface SubscriptionProps {
  isOpen?: boolean;
  setIsOpen?: (isOpen: boolean) => void;
  onClose?: () => void;
}

interface SubscriptionStatus {
  status: string;
  planId?: string;
  endsAt?: string;
  billingCycle?: string;
}

interface CachedStatus {
  data: SubscriptionStatus;
  timestamp: number;
}

// In-memory cache
let subscriptionCache: CachedStatus | null = null;

export function Subscription({ isOpen = true, setIsOpen, onClose }: SubscriptionProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [isInitializing, setIsInitializing] = useState(true);
  const [message, setMessage] = useState<{ type: 'success' | 'error', text: string } | null>(null);
  const [subscriptionStatus, setSubscriptionStatus] = useState<SubscriptionStatus | null>(null);

  const isCacheValid = () => {
    return subscriptionCache && 
           (Date.now() - subscriptionCache.timestamp) < CACHE_DURATION;
  };

  const fetchSubscriptionStatus = async (force = false) => {
    try {
      // Check cache first unless force refresh is requested
      if (!force && isCacheValid()) {
        setSubscriptionStatus(subscriptionCache!.data);
        setIsInitializing(false);
        return;
      }

      const status = await stripeService.getSubscriptionStatus();
      
      // Update cache
      subscriptionCache = {
        data: status,
        timestamp: Date.now()
      };
      
      setSubscriptionStatus(status);
    } catch (error) {
      console.error('Error fetching subscription status:', error);
      setMessage({ 
        type: 'error', 
        text: 'Failed to load subscription status. Please try again.' 
      });
      // If cache exists, use it as fallback
      if (subscriptionCache) {
        setSubscriptionStatus(subscriptionCache.data);
      }
    } finally {
      setIsInitializing(false);
    }
  };

  useEffect(() => {
    fetchSubscriptionStatus();
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const success = searchParams.get('success');
    
    if (success === 'true') {
      setMessage({ type: 'success', text: 'Thank you for subscribing! Your subscription is now active.' });
      // Force refresh after successful subscription
      fetchSubscriptionStatus(true);
      navigate('/subscription', { replace: true });
    } else if (success === 'false') {
      setMessage({ type: 'error', text: 'The payment was cancelled. Please try again.' });
      navigate('/subscription', { replace: true });
    }
  }, [location.search, navigate]);

  const handlePortalAccess = async () => {
    try {
      setIsLoading(true);
      const { url } = await stripeService.createPortalSession();
      window.location.href = url;
    } catch (error) {
      console.error('Error accessing customer portal:', error);
      setMessage({ 
        type: 'error', 
        text: error instanceof Error ? error.message : 'Failed to access customer portal. Please try again.' 
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    if (onClose) {
      onClose();
    } else if (setIsOpen) {
      setIsOpen(false);
    } else {
      if (location.pathname === '/subscription') {
        navigate(-1);
      }
    }
  };

  const handleUpgrade = async () => {
    try {
      setIsLoading(true);
      setMessage(null);
      
      const { url } = await stripeService.createCheckoutSession();
      window.location.href = url;
    } catch (error) {
      console.error('Error creating payment link:', error);
      setMessage({ 
        type: 'error', 
        text: error instanceof Error ? error.message : 'Failed to create payment link. Please try again.' 
      });
    } finally {
      setIsLoading(false);
    }
  };

  if (!isOpen) return null;

  const isSubscribed = subscriptionStatus?.status === 'active' || subscriptionStatus?.status === 'active_canceling';
  const isCanceling = subscriptionStatus?.status === 'active_canceling';

  return (
    <div className="fixed inset-0 bg-white z-50 overflow-y-auto">
      <div className="max-w-xl mx-auto px-4 py-12">
        {isInitializing ? (
          <div className="animate-pulse">
            {/* Loading Header */}
            <div className="text-center mb-12">
              <div className="h-9 w-48 bg-gray-200 rounded mx-auto" />
            </div>

            {/* Loading Plan Card */}
            <div className="border border-gray-200 rounded-2xl p-8 shadow-sm space-y-4">
              {/* Title */}
              <div className="h-6 w-24 bg-gray-200 rounded" />
              
              {/* Price */}
              <div className="flex items-baseline gap-1">
                <div className="h-8 w-16 bg-gray-200 rounded" />
                <div className="h-4 w-16 bg-gray-200 rounded" />
              </div>
              
              {/* Description */}
              <div className="h-4 w-full bg-gray-200 rounded" />
              
              {/* Button */}
              <div className="h-14 w-full bg-gray-200 rounded-md my-6" />
              
              {/* Features List */}
              <div className="space-y-4 pt-2">
                {[1, 2, 3, 4, 5].map((i) => (
                  <div key={i} className="flex items-center gap-3">
                    <div className="h-5 w-5 bg-gray-200 rounded-full flex-shrink-0" />
                    <div className="h-4 w-full bg-gray-200 rounded" />
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : (
          <>
            {/* Header */}
            <div className="text-center mb-12">
              <h1 className="text-3xl font-medium text-gray-800">
                {isSubscribed ? 'Your Subscription' : 'Upgrade your plan'}
              </h1>
              {isCanceling && (
                <div className="mt-4 p-4 rounded-md bg-yellow-50 text-yellow-800">
                  Your subscription will be canceled at the end of the current billing period
                  {subscriptionStatus?.endsAt && ` (${new Date(subscriptionStatus.endsAt).toLocaleDateString()})`}
                </div>
              )}
              {message && (
                <div className={`mt-4 p-4 rounded-md ${
                  message.type === 'success' ? 'bg-green-50 text-green-800' : 'bg-red-50 text-red-800'
                }`}>
                  {message.text}
                </div>
              )}
            </div>

            {/* Plan Card */}
            <div className="border border-gray-200 rounded-2xl p-8 shadow-sm">
              <h2 className="text-xl font-medium text-gray-800">Standard</h2>
              <div className="mt-4 mb-2">
                <span className="text-3xl font-medium">€</span>
                <span className="text-4xl font-medium">450</span>
                <span className="text-gray-600 text-base ml-1">/month</span>
              </div>
              <p className="text-gray-600 text-base mb-6">
                Supercharge your team's work with a secure, collaborative workspace
              </p>

              {isSubscribed ? (
                <button 
                  onClick={handlePortalAccess}
                  disabled={isLoading}
                  className="w-full border border-[#1e8259] text-[#1e8259] py-3.5 px-4 rounded-md hover:bg-[#1e8259]/5 transition-all duration-200 mb-6 font-medium shadow-sm hover:shadow-md disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  {isLoading ? 'Loading...' : 'Manage Subscription'}
                </button>
              ) : (
                <button 
                  onClick={handleUpgrade}
                  disabled={isLoading}
                  className="w-full bg-[#1e8259] text-white py-3.5 px-4 rounded-md hover:bg-[#1e8259]/90 transition-all duration-200 mb-6 font-medium shadow-sm hover:shadow-md disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  {isLoading ? 'Processing...' : 'Upgrade to Standard'}
                </button>
              )}

              <ul className="space-y-4">
                <li className="flex items-start text-base text-gray-700">
                  <svg className="h-5 w-5 text-[#1e8259] mr-3 flex-shrink-0 mt-0.5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                  </svg>
                  <span>20 AI-powered questionnaires monthly</span>
                </li>
                <li className="flex items-center text-base text-gray-700">
                  <svg className="h-5 w-5 text-[#1e8259] mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                  </svg>
                  Unlimited AI knowledge items
                </li>
                <li className="flex items-center text-base text-gray-700">
                  <svg className="h-5 w-5 text-[#1e8259] mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                  </svg>
                  Dedicated customer support
                </li>
                <li className="flex items-center text-base text-gray-700">
                  <svg className="h-5 w-5 text-[#1e8259] mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                  </svg>
                  Enhanced security with 2FA (coming soon)
                </li>
                <li className="flex items-center text-base text-gray-700">
                  <svg className="h-5 w-5 text-[#1e8259] mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                  </svg>
                  Add additional team members (coming soon)
                </li>
              </ul>
            </div>

            {/* Footer */}
            <div className="text-center mt-8">
              <div className="inline-flex items-center justify-center text-base text-gray-600">
                <svg className="h-5 w-5 text-gray-500 mr-2" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6 6 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z" />
                </svg>
                Need more capabilities for your business?
              </div>
              <div className="mt-1">
                <a 
                  href="mailto:service@basteon.com?subject=Enterprise%20Plans"
                  className="text-[#1e8259] hover:text-[#1e8259]/80 font-medium text-base inline-block"
                >
                  Contact us
                </a>
              </div>
            </div>
          </>
        )}

        {/* Close button - always visible */}
        <div className="absolute top-4 right-4">
          <button 
            onClick={handleClose}
            className="text-gray-400 hover:text-gray-500 p-2"
          >
            <svg className="h-6 w-6" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={1.5}>
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
}