import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import BasteonLogo from '../../assets/basteon-logo.svg';

export const EmailVerification: React.FC = () => {
  const [code, setCode] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [remainingAttempts, setRemainingAttempts] = useState(3);
  const [searchParams] = useSearchParams();
  const { verifyEmail, resendVerificationCode } = useAuth();
  const navigate = useNavigate();

  // Get email from URL parameter
  const email = searchParams.get('email');
  
  useEffect(() => {
    // Redirect to signup if no email in URL
    if (!email) {
      navigate('/signup', { replace: true });
    }
  }, [email, navigate]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!email) {
      navigate('/signup', { replace: true });
      return;
    }

    if (remainingAttempts <= 0) {
      setError('Too many attempts. Please request a new verification code.');
      return;
    }

    setError('');
    setIsLoading(true);

    try {
      if (!code) {
        throw new Error('Please enter verification code');
      }

      await verifyEmail(email, code.trim());
      navigate('/questionnaires', { replace: true });
    } catch (err: any) {
      console.error('Verification error:', err);
      setRemainingAttempts(prev => prev - 1);
      setError(err.message || 'Verification failed');
    } finally {
      setIsLoading(false);
    }
  };

  const handleResendCode = async () => {
    if (!email) {
      navigate('/signup', { replace: true });
      return;
    }

    try {
      setIsLoading(true);
      await resendVerificationCode(email);
      setRemainingAttempts(3); // Reset attempts after new code
      alert('A new verification code has been sent to your email');
    } catch (err: any) {
      setError(err.message || 'Failed to resend code');
    } finally {
      setIsLoading(false);
    }
  };

  if (!email) {
    return null; // Will redirect in useEffect
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50/50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full">
        <div className="text-center mb-8">
          <div className="flex justify-center mb-10">
            <img 
              src={BasteonLogo} 
              alt="Basteon" 
              className="h-8 w-auto"
            />
          </div>
          <h2 className="text-3xl font-semibold text-gray-900">Verify your email</h2>
          <p className="mt-2 text-sm text-gray-600">
            Please enter the verification code sent to {email}
          </p>
          {remainingAttempts < 3 && (
            <p className="mt-2 text-sm text-amber-600">
              {remainingAttempts} attempts remaining
            </p>
          )}
        </div>

        <div className="bg-white shadow-sm border border-gray-200 rounded-xl p-8">
          <form className="space-y-6" onSubmit={handleSubmit}>
            {error && (
              <div className="rounded-lg bg-red-50 p-4">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <svg className="h-5 w-5 text-red-400" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                        d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                  </div>
                  <div className="ml-3">
                    <div className="text-sm text-red-800">{error}</div>
                  </div>
                </div>
              </div>
            )}

            <div className="space-y-5">
              <div>
                <label htmlFor="code" className="block text-sm font-medium text-gray-700">
                  Verification code
                </label>
                <div className="mt-1">
                  <input
                    id="code"
                    type="text"
                    required
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                    className="appearance-none block w-full px-3 py-2.5 border border-gray-300 rounded-lg shadow-sm 
                      placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-[#1e8259] focus:border-[#1e8259] sm:text-sm"
                    placeholder="Enter verification code"
                    maxLength={6}
                    autoComplete="one-time-code"
                  />
                </div>
              </div>
            </div>

            <div>
              <button
                type="submit"
                disabled={isLoading || remainingAttempts <= 0}
                className={`w-full flex justify-center py-2.5 px-4 border border-transparent rounded-lg shadow-sm text-sm 
                  font-medium text-white transition-all duration-200 ${
                    isLoading || remainingAttempts <= 0
                      ? 'bg-gray-400 cursor-not-allowed'
                      : 'bg-[#1e8259] hover:bg-[#1e8259]/90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#1e8259]'
                  }`}
              >
                {isLoading ? (
                  <span className="flex items-center">
                    <svg className="animate-spin -ml-1 mr-3 h-4 w-4 text-white" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      />
                    </svg>
                    Verifying...
                  </span>
                ) : (
                  'Verify Email'
                )}
              </button>
            </div>

            <div className="text-center">
              <button
                type="button"
                onClick={handleResendCode}
                disabled={isLoading}
                className={`text-sm font-medium ${
                  isLoading 
                    ? 'text-gray-400 cursor-not-allowed' 
                    : 'text-[#1e8259] hover:text-[#1e8259]/80'
                }`}
              >
                Resend verification code
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}; 