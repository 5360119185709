import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import BasteonLogo from '../../assets/basteon-logo.svg';

interface SignUpError {
  field?: string;
  message: string;
}

interface SignUpFormData {
  email: string;
  password: string;
  passwordConfirm: string;
  givenName: string;
  familyName: string;
  organizationName: string;
  acceptedTerms: boolean;
}

export const SignUpForm: React.FC = () => {
  const [formData, setFormData] = useState<SignUpFormData>({
    email: '',
    password: '',
    passwordConfirm: '',
    givenName: '',
    familyName: '',
    organizationName: '',
    acceptedTerms: false
  });
  const [error, setError] = useState<SignUpError | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const { signUp } = useAuth();
  const navigate = useNavigate();

  const validatePassword = (password: string) => {
    const hasMinLength = password.length >= 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /[0-9]/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    if (!hasMinLength) {
      return 'Password must be at least 8 characters long';
    }
    if (!hasUpperCase) {
      return 'Password must contain at least one uppercase letter';
    }
    if (!hasLowerCase) {
      return 'Password must contain at least one lowercase letter';
    }
    if (!hasNumber) {
      return 'Password must contain at least one number';
    }
    if (!hasSpecialChar) {
      return 'Password must contain at least one special character';
    }
    return null;
  };

  const validateForm = (formData: SignUpFormData) => {
    if (!formData.email) {
      return {
        field: 'email',
        message: 'Email is required'
      };
    }

    if (!formData.organizationName) {
      return {
        field: 'organizationName',
        message: 'Company name is required'
      };
    }

    if (!formData.givenName || !formData.familyName) {
      return {
        field: 'name',
        message: 'First and last name are required'
      };
    }

    if (!formData.password) {
      return {
        field: 'password',
        message: 'Password is required'
      };
    }

    const passwordError = validatePassword(formData.password);
    if (passwordError) {
      return {
        field: 'password',
        message: passwordError
      };
    }

    if (!formData.acceptedTerms) {
      return {
        field: 'terms',
        message: 'You must accept the Terms of Service and Privacy Policy'
      };
    }

    return null;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);

    const validationError = validateForm(formData);
    if (validationError) {
      setError(validationError);
      return;
    }

    setIsLoading(true);
    try {
      await signUp(formData.email, formData.password, {
        givenName: formData.givenName,
        familyName: formData.familyName,
        organizationName: formData.organizationName.trim() || `${formData.givenName}'s Organization`
      });
      navigate('/verify-email');
    } catch (err: any) {
      console.error('Signup error:', err);
      if (err.response?.data?.error?.includes('password')) {
        setError({
          field: 'password',
          message: 'Password must contain at least 8 characters, including 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character.'
        });
      } else if (err.response?.data?.error?.includes('email')) {
        setError({
          field: 'email',
          message: err.message || 'Invalid email address.'
        });
      } else {
        setError({
          field: undefined,
          message: err.message || 'Something went wrong. Please try again.'
        });
      }
      setIsLoading(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    setFormData(prev => ({
      ...prev,
      [e.target.name]: value
    }));
  };

  const renderError = () => {
    if (!error) return null;

    return (
      <div className="rounded-lg bg-red-50 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <svg className="h-5 w-5 text-red-400" viewBox="0 0 24 24" fill="none" stroke="currentColor">
              <path 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                strokeWidth={2} 
                d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" 
              />
            </svg>
          </div>
          <div className="ml-3">
            <div className="text-sm text-red-800">{error.message}</div>
          </div>
        </div>
      </div>
    );
  };

  const getInputClassName = (fieldName: string) => `
    appearance-none block w-full px-3 py-2.5 border 
    ${error?.field === fieldName ? 'border-red-300' : 'border-gray-300'} 
    rounded-lg shadow-sm placeholder-gray-400 
    focus:outline-none focus:ring-1 
    ${error?.field === fieldName ? 'focus:ring-red-500 focus:border-red-500' : 'focus:ring-[#1e8259] focus:border-[#1e8259]'} 
    sm:text-sm
    transition-colors
  `;

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50/50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full">
        <div className="text-center mb-8">
          <div className="flex justify-center mb-10">
            <img 
              src={BasteonLogo} 
              alt="Basteon" 
              className="h-8 w-auto"
            />
          </div>
          <h2 className="text-3xl font-semibold text-gray-900">Create account</h2>
          <p className="mt-2 text-sm text-gray-600">Fill in your information to get started</p>
        </div>

        <div className="bg-white shadow-sm border border-gray-200 rounded-xl p-8">
          <form className="space-y-6" onSubmit={handleSubmit}>
            {renderError()}

            <div className="space-y-5">
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                  Email address
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    required
                    value={formData.email}
                    onChange={handleChange}
                    className={getInputClassName('email')}
                    placeholder="Enter your email"
                  />
                </div>
              </div>

              <div>
                <label htmlFor="organizationName" className="block text-sm font-medium text-gray-700">
                  Company name
                </label>
                <div className="mt-1">
                  <input
                    id="organizationName"
                    name="organizationName"
                    type="text"
                    required
                    value={formData.organizationName}
                    onChange={handleChange}
                    className={getInputClassName('organizationName')}
                    placeholder="Enter your company name"
                  />
                </div>
              </div>

              <div>
                <label htmlFor="givenName" className="block text-sm font-medium text-gray-700">
                  First name
                </label>
                <div className="mt-1">
                  <input
                    id="givenName"
                    name="givenName"
                    type="text"
                    required
                    value={formData.givenName}
                    onChange={handleChange}
                    className={getInputClassName('givenName')}
                    placeholder="Enter your first name"
                  />
                </div>
              </div>

              <div>
                <label htmlFor="familyName" className="block text-sm font-medium text-gray-700">
                  Last name
                </label>
                <div className="mt-1">
                  <input
                    id="familyName"
                    name="familyName"
                    type="text"
                    required
                    value={formData.familyName}
                    onChange={handleChange}
                    className={getInputClassName('familyName')}
                    placeholder="Enter your last name"
                  />
                </div>
              </div>

              <div>
                <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                  Password
                </label>
                <div className="mt-1">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    required
                    value={formData.password}
                    onChange={handleChange}
                    className={getInputClassName('password')}
                    placeholder="Create a password"
                  />
                  <p className="mt-2 text-sm text-gray-400">
                    8+ characters with uppercase, lowercase, number & symbol
                  </p>
                </div>
              </div>

              <div>
                <label htmlFor="passwordConfirm" className="block text-sm font-medium text-gray-700">
                  Confirm Password
                </label>
                <div className="mt-1">
                  <input
                    id="passwordConfirm"
                    name="passwordConfirm"
                    type="password"
                    required
                    value={formData.passwordConfirm}
                    onChange={handleChange}
                    className={getInputClassName('passwordConfirm')}
                    placeholder="Confirm your password"
                  />
                </div>
              </div>

              <div>
                <div className="mt-4">
                  <div className="relative flex items-start">
                    <div className="flex h-5 items-center">
                      <input
                        id="acceptedTerms"
                        name="acceptedTerms"
                        type="checkbox"
                        checked={formData.acceptedTerms}
                        onChange={handleChange}
                        className={`h-4 w-4 rounded border-gray-300 text-[#1e8259] focus:ring-[#1e8259] ${
                          error?.field === 'terms' ? 'border-red-300' : ''
                        }`}
                      />
                    </div>
                    <div className="ml-3 text-sm">
                      <label htmlFor="acceptedTerms" className="text-gray-600">
                        I agree to the{' '}
                        <a href="https://www.basteon.com/terms" target="_blank" rel="noopener noreferrer" className="text-[#1e8259] hover:text-[#1e8259]/80">
                          Terms of Service
                        </a>{' '}
                        and{' '}
                        <a href="https://www.basteon.com/privacy-policy" target="_blank" rel="noopener noreferrer" className="text-[#1e8259] hover:text-[#1e8259]/80">
                          Privacy Policy
                        </a>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <button
                type="submit"
                disabled={isLoading}
                className={`w-full flex justify-center py-2.5 px-4 border border-transparent rounded-lg shadow-sm text-sm 
                  font-medium text-white transition-all duration-200 ${
                    isLoading
                      ? 'bg-gray-400 cursor-not-allowed'
                      : 'bg-[#1e8259] hover:bg-[#1e8259]/90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#1e8259]'
                  }`}
              >
                {isLoading ? (
                  <span className="flex items-center">
                    <svg className="animate-spin -ml-1 mr-3 h-4 w-4 text-white" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      />
                    </svg>
                    Creating account...
                  </span>
                ) : (
                  'Create account'
                )}
              </button>
            </div>
          </form>
        </div>

        <div className="mt-6 text-center">
          <p className="text-sm text-gray-600">
            Already have an account?{' '}
            <Link to="/login" className="font-medium text-[#1e8259] hover:text-[#1e8259]/80">
              Sign in instead
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
}; 