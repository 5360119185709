import { ApiGatewayService } from './apiGatewayService';
import { CsrfService } from './CsrfService';
import { AuthService } from './AuthService';

// Create instances
const apiGatewayService = new ApiGatewayService(import.meta.env.VITE_API_URL || '');
const csrfService = new CsrfService(apiGatewayService);
const authService = new AuthService(apiGatewayService, csrfService);

// Export instances and types
export {
  apiGatewayService,
  csrfService,
  authService,
  ApiGatewayService
};