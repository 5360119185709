import { ApiGatewayService } from './apiGatewayService';

const api = new ApiGatewayService(import.meta.env.VITE_API_URL || '');

export interface TeamMember {
  id: string;
  email: string;
  givenName: string;
  familyName: string;
  role: string;
}

class TeamService {
  async getTeamMembers() {
    const response = await api.request({
      method: 'GET',
      url: '/api/team/members',
    });
    return response.data;
  }

  async updateMemberRole(userId: string, role: string) {
    const response = await api.request({
      method: 'PUT',
      url: `/api/team/members/${userId}/role`,
      data: { role }
    });
    return response.data;
  }

  async inviteMember(email: string, role: string) {
    const response = await api.request({
      method: 'POST',
      url: '/api/team/invite',
      data: { email, role }
    });
    return response.data;
  }

  async removeMember(userId: string) {
    const response = await api.request({
      method: 'DELETE',
      url: `/api/team/members/${userId}`,
    });
    return response.data;
  }
}

export default new TeamService(); 