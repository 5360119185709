import React, { createContext, useContext, useState, useEffect } from 'react';
import { ApiGatewayService } from '../services/apiGatewayService';
import { CsrfService } from '../services/CsrfService';
import { AuthService } from '../services/AuthService';
import { AuthSession, SessionUser, AuthResult } from '../types/authentication';
import { useQueryClient } from '@tanstack/react-query';
import { useLocation, useNavigate } from 'react-router-dom';

// Public routes that don't need session checks
const PUBLIC_ROUTES = [
  '/login',
  '/signup',
  '/forgot-password',
  '/reset-password',
  '/verify-email'
];

interface AuthContextType {
  user: SessionUser | null;
  isAuthenticated: boolean;
  isLoading: boolean;
  signIn: (
    email: string, 
    password: string, 
    newPassword?: string,
    userInfo?: { firstName: string; lastName: string }
  ) => Promise<AuthResult>;
  signOut: () => Promise<void>;
  signUp: (email: string, password: string, userData: {
    givenName: string;
    familyName: string;
    organizationName: string;
  }) => Promise<void>;
  verifyEmail: (email: string, code: string) => Promise<void>;
  resendVerificationCode: (email: string) => Promise<void>;
  forgotPassword: (email: string) => Promise<void>;
  confirmPasswordReset: (email: string, code: string, newPassword: string) => Promise<void>;
  updateUserAttributes: (attributes: { [key: string]: string }) => Promise<void>;
  changePassword: (currentPassword: string, newPassword: string) => Promise<void>;
  getCurrentSession: () => Promise<AuthSession | null>;
}

const AuthContext = createContext<AuthContextType | null>(null);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<SessionUser | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const queryClient = useQueryClient();
  const location = useLocation();
  const navigate = useNavigate();

  const gateway = new ApiGatewayService(import.meta.env.VITE_API_URL || '');
  const csrfService = new CsrfService(gateway);
  const authService = new AuthService(gateway, csrfService);

  // Check if current route is a public route
  const isPublicRoute = PUBLIC_ROUTES.some(route => 
    location.pathname.startsWith(route)
  );

  useEffect(() => {
    const initializeAuth = async () => {
      try {
        // Skip session check for public routes
        if (isPublicRoute) {
          setIsLoading(false);
          return;
        }

        // Skip if we already have a valid user session
        if (isAuthenticated && user) {
          setIsLoading(false);
          return;
        }

        setIsLoading(true);
        const session = await authService.getCurrentSession();
        
        if (session?.user) {
          setUser(session.user);
          setIsAuthenticated(true);
        } else {
          // If no session and not on a public route, redirect to login
          navigate('/login', { 
            replace: true,
            state: { from: location.pathname }
          });
        }
      } catch (error: any) {
        // Only log errors that aren't 401s
        if (error?.response?.status !== 401) {
          console.error('Auth initialization error:', error);
        }
        // If error and not on public route, redirect to login
        if (!isPublicRoute) {
          navigate('/login', { 
            replace: true,
            state: { from: location.pathname }
          });
        }
      } finally {
        setIsLoading(false);
      }
    };

    initializeAuth();
  }, [location.pathname, isPublicRoute]);

  const signIn = async (
    email: string, 
    password: string, 
    newPassword?: string,
    userInfo?: { firstName: string; lastName: string }
  ): Promise<AuthResult> => {
    setIsLoading(true);
    try {
      const response = await authService.signIn(email, password, newPassword, userInfo);
      
      if (response.error === 'NEW_PASSWORD_REQUIRED') {
        return {
          session: response.session,
          error: response.error,
          challengeName: 'NEW_PASSWORD_REQUIRED'
        };
      }
      
      if (response.error && response.pendingApproval) {
        return {
          session: response.session,
          error: response.error,
          pendingApproval: true
        };
      }
      
      if (response.session?.user) {
        setUser(response.session.user);
        setIsAuthenticated(true);
        return response;
      }
      
      return {
        error: response.error || 'Invalid login response'
      };
    } catch (error: any) {
      return {
        error: error?.message || 'An error occurred during sign in'
      };
    } finally {
      setIsLoading(false);
    }
  };

  const signOut = async () => {
    setIsLoading(true);
    try {
      await authService.signOut();
      queryClient.clear();
      setUser(null);
      setIsAuthenticated(false);
    } finally {
      setIsLoading(false);
    }
  };

  const signUp = async (email: string, password: string, userData: {
    givenName: string;
    familyName: string;
    organizationName: string;
  }) => {
    await authService.signUp(email, password, userData);
  };

  const verifyEmail = async (email: string, code: string) => {
    await authService.verifyEmail(email, code);
  };

  const resendVerificationCode = async (email: string) => {
    await authService.resendVerificationCode(email);
  };

  const forgotPassword = async (email: string) => {
    await authService.forgotPassword(email);
  };

  const confirmPasswordReset = async (email: string, code: string, newPassword: string) => {
    await authService.confirmPasswordReset(email, code, newPassword);
  };

  const updateUserAttributes = async (attributes: { [key: string]: string }) => {
    await authService.updateUserAttributes(attributes);
  };

  const changePassword = async (currentPassword: string, newPassword: string) => {
    await authService.changePassword(currentPassword, newPassword);
  };

  const getCurrentSession = async (): Promise<AuthSession | null> => {
    return await authService.getCurrentSession();
  };

  const value: AuthContextType = {
    user,
    isAuthenticated,
    isLoading,
    signIn,
    signOut,
    signUp,
    verifyEmail,
    resendVerificationCode,
    forgotPassword,
    confirmPasswordReset,
    updateUserAttributes,
    changePassword,
    getCurrentSession
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
}; 